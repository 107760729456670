<template>
  <v-card flat class="">
    <v-data-table dense :headers="table.headers" :items="table.items" :options.sync="tableOptions"
      :server-items-length="table.totalItems" :loading="table.loading" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title><b>Master Pertanyaan Evaluasi</b></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="table.search" @change="getData()" :prepend-inner-icon="icons.mdiMagnify"
            label="Cari dengan nama/jenis" single-line hide-details></v-text-field>

          <!-- dialog pelatihan -->
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="add()">
                Tambah
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h7" v-if="form.editedIndex === -1">Tambah Data</span>
                <span class="text-h7" v-else>Edit Data</span>
              </v-card-title>
              <v-card-text>
                <label class="text">Jenis Pertanyaan:</label>
                <v-radio-group inline v-model="form.edit.jenis">
                  <v-radio label="PENYELENGGARA" value="PENYELENGGARA"></v-radio>
                  <v-radio label="NARASUMBER" value="NARASUMBER"></v-radio>
                </v-radio-group>
                <v-textarea label="Pertanyaan:" v-model="form.edit.pertanyaan" dense outlined></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="save()"> Simpan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <span v-if="item.is_belum_terpakai == 1">
          <v-btn title="Lihat" x-small color="primary" @click="editData(item)">
            <v-icon x-small>
              {{ icons.mdiEye }}
            </v-icon>
          </v-btn>
        </span>
      </template>
      <template v-slot:no-data>
        <v-btn x-small color="primary" @click="getData()"> Reload </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
  components: {},
  data: () => ({
    icons: {
      mdiEye,
      mdiPencil,
      mdiMagnify,
    },
    tableOptions: {},
    table: {
      loading: true,
      headers: [
        { text: 'No', align: 'start', sortable: false, value: 'no' },
        { text: 'Jenis', value: 'jenis', sortable: false },
        { text: 'Pertanyaan', value: 'pertanyaan', sortable: false },
        { text: 'Aksi', value: 'actions', sortable: false },
      ],
      items: [],
      totalItems: 0,
      search: '',
    },
    dialog: false,
    form: {
      editedIndex: -1,
      edit: {
        pertanyaan: '',
        jenis: '',
      },
      default: {
        pertanyaan: '',
        jenis: '',
      },
    },
  }),
  watch: {
    tableOptions: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  computed: {
    // formTitle() {
    //   return this.form.editedIndex === -1 ? 'Tambah Data' : 'Lihat/Edit Data'
    // },
  },
  methods: {
    save() {

      if (this.form.editedIndex > -1) {
        const fmData = new FormData()
        fmData.append('aksi', 'update')
        fmData.append('id', this.form.edit.id)
        fmData.append('jenis', this.form.edit.jenis)
        fmData.append('pertanyaan', this.form.edit.pertanyaan)
        axiosPostAuth('api/Evaluasi/saveMasterPertanyaan', fmData)
          .then(response => {
            if (response.code === 200) {
              this.$notify({
                type: 'success',
                title: 'Berhasil!',
                text: response.message,
              });
              this.dialog = false
              this.getData()
            } else {
              alert(response.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
        const fmData = new FormData()
        fmData.append('aksi', 'add')
        fmData.append('jenis', this.form.edit.jenis)
        fmData.append('pertanyaan', this.form.edit.pertanyaan)
        axiosPostAuth('api/Evaluasi/saveMasterPertanyaan', fmData)
          .then(response => {
            if (response.code === 200) {
              this.$notify({
                type: 'success',
                title: 'Berhasil!',
                text: response.message,
              });
              this.getData()
            } else {
              alert(response.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    getData() {
      const { page, itemsPerPage } = this.tableOptions
      const fmData = new FormData()
      fmData.append('page', page)
      fmData.append('itemsPerPage', itemsPerPage)
      fmData.append('search', this.table.search)
      axiosPostAuth('api/Evaluasi/getMasterPertanyaan_datatable', fmData)
        .then(response => {
          this.table.items = response.data
          this.table.totalItems = response.total
          this.table.loading = false
        })
        .catch(error => {
          alert(error)
        })
    },
    add() {
      this.form.edit = Object.assign({}, this.form.default)
      this.form.editedIndex = -1
    },
    editData(item) {
      this.form.editedIndex = this.table.items.indexOf(item)
      this.form.edit = Object.assign({}, item)
      this.$store.commit('setUserSelected', item)
      this.dialog = true
    },
  },
}
</script>